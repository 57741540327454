import React from "react"
import Header from "../components/Header"
import Logo from "../components/Logo"
import LogoSplitted from "../components/LogoSplitted"
import SplashText from "../components/SplashText"
import useWindowSize from "../hooks/useWindowSize"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

export default function Home() {
  const { width, height } = useWindowSize()

  return (
    <div
      style={{ minHeight: height }}
      className=" bg-red p-2 md:p-4 flex flex-col justify-between"
    >
      {width < 800 ? (
        <>
          <SplashText />
          <div>
            <Header />
            {/* <Logo /> */}
            <LogoSplitted />
          </div>
        </>
      ) : (
        <>
          <Header />
          <SplashText />
          <Logo />
        </>
      )}
    </div>
  )
}

export const Head = () => {
  const data = useSiteMetadata()
  console.log(data.site.siteMetadata)
  return (
    <>
      <title>{data.site.siteMetadata.title}</title>
      <meta name="description" content={data.site.siteMetadata.description} />
      <meta name="keywords" content={data.site.siteMetadata.keywords} />
      <meta name="author" content={data.site.siteMetadata.author} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </>
  )
}
