import React from "react"

const LogoS = () => {
  return (
    <svg version="1.1" viewBox="0 0 1128.8 841.9">
      <path
        id="Path_3"
        d="M904.3,348.7l-160.2-19.5l-449.8-55.8c-3.8-0.7-6.3-4.3-5.6-8.1c0.6-3.3,3.5-5.7,6.8-5.7h779.7V5H259.6
	C119,5,5.6,100.8,5.6,241.5c0,112.5,81.2,228.9,218.9,251.7l126.4,17.5l0,0l394.2,47.1l0,0l89.4,10.7c3.8,0.7,6.3,4.3,5.6,8.1
	c-0.6,3.3-3.5,5.7-6.8,5.7H53.5v254.7h815.7c140.6,0,254-95.8,254-236.5C1123.2,487.9,1042,371.5,904.3,348.7"
      />
    </svg>
  )
}
export default LogoS
