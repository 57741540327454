import React from "react"

const Header = () => {
  return (
    <div className="md:flex md:flex-row md:justify-between md:flex-wrap grid grid-cols-3 gap-x-2 items-end md:items-start md:pb-0 pb-2 leading-[1] text-[0.62rem] sm:text-lg uppercase ">
      <div className="flex flex-col leading-[1] font-default">
        controlled
        <br />
        damage
        <br />
        solutions
        <br />
      </div>
      <a
        href="mailto:info@controlleddamagesolutions.com"
        className="md:pt-0 pt-2 font-default case-open-type"
      >
        info@controlleddamagesolutions.com
      </a>
    </div>
  )
}

export default Header
