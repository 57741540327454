import React from "react"

const LogoD = () => {
  return (
    <svg version="1.1" viewBox="0 0 1112.6 841.9">
      <path
        id="Path_1"
        d="M857,5H4v831.9h853c138.9,0,251.5-112.6,251.5-251.5V256.5C1108.5,117.6,995.9,5,857,5 M798.4,585.4H255.6
	V256.6h542.8c32.4,0,58.6,26.2,58.6,58.6v211.6C857,559.1,830.8,585.4,798.4,585.4L798.4,585.4"
      />
    </svg>
  )
}
export default LogoD
