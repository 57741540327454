import React from "react"

const SplashText = () => {
  return (
    <div className="font-default text-5xl md:text-8xl font-light uppercase">
      We produce, we service, we solve problems by avoiding damages.
    </div>
  )
}

export default SplashText
