import React from "react"
import LogoC from "./Logo/LogoC"
import LogoD from "./Logo/LogoD"
import LogoS from "./Logo/LogoS"

const letters = [<LogoC />, <LogoD />, <LogoS />]

const LogoSplitted = () => {
  return (
    <div className="grid grid-cols-3 gap-x-2 ">
      {letters.map((letter, index) => {
        return <div key={`letter-${index}`}>{letter}</div>
      })}
    </div>
  )
}
export default LogoSplitted
