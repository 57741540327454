import React from "react"

const Logo = () => {
  return (
    <svg id="Group_2" data-name="Group 2" viewBox="0 0 1870.69 453.372">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="1870.69"
            height="453.372"
          />
        </clipPath>
      </defs>
      <g id="Group_1" data-name="Group 1" clipPath="url(#clip-path)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M712.391,0H247.526V453.374H712.391A137.089,137.089,0,0,0,849.48,316.285v-179.2A137.091,137.091,0,0,0,712.391,0M680.449,316.285H384.615V137.093H680.449a31.941,31.941,0,0,1,31.942,31.937V284.343a31.942,31.942,0,0,1-31.942,31.942"
          transform="translate(390.049 -0.002)"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M601.954,316.285H169.031a31.942,31.942,0,0,1-31.942-31.942V169.03a31.941,31.941,0,0,1,31.942-31.937H601.954V0H137.089A137.091,137.091,0,0,0,0,137.088v179.2A137.089,137.089,0,0,0,137.089,453.374H601.954Z"
          transform="translate(0 -0.002)"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M976.478,187.188,890.244,176.7,648.106,146.67a3.747,3.747,0,0,1,.667-7.434H1068.55V2.148H629.442c-75.715,0-136.746,51.583-136.746,127.3,0,60.567,43.688,123.239,117.827,135.5l67.928,8.428.1,1.01,212.219,25.333h.005l48.119,5.744a3.747,3.747,0,0,1-.667,7.434H518.454V449.987H957.562c75.713,0,136.746-51.583,136.746-127.3,0-60.567-43.69-123.239-117.83-135.5"
          transform="translate(776.385 3.385)"
        />
      </g>
    </svg>
  )
}

export default Logo
