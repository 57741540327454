import React from "react"

const LogoC = () => {
  return (
    <svg version="1.1" viewBox="0 0 1112.6 841.9">
      <g id="Group_1">
        <path
          id="Path_2"
          d="M1108.5,585.3H314.2c-32.4,0-58.6-26.2-58.6-58.6V315.1c0-32.4,26.2-58.6,58.6-58.6h794.4V5h-853
       C116.6,5,4,117.6,4,256.5v328.8c0,138.9,112.6,251.5,251.5,251.5h853V585.3z"
        />
      </g>
    </svg>
  )
}
export default LogoC
